import config from "../config";
import api from '@/common/api';

/**
 * イベントログの操作 ID 発行
 * @param netAppId netAppId
 */
export const createEventLogHandleId = async (netAppId, apiType=config.DIAG) => {
  // イベントログの操作 ID 発行 API の実行
  await api.postCall(
    apiType,
    '/netApps/' + netAppId + '/eventLogs/handleId', {}
  );
};

export default {
  createEventLogHandleId
};
